import * as React from "react"
import Seo from "../components/common/seo.js"
import Layout from "../components/common/layout.js"
import SoftwareSec from "../components/training-webinars/training-webinars.js"
import Hero from "../components/events/awards-hero-2024.js"
import SponsorsEN from "../images/awards/Sponsors-EN.png"
import SponsorsFR from "../images/awards/Sponsors-FR.png"

import AwardsImg14 from "../images/awards/awards-14.png"
import AwardsImg26 from "../images/awards/26-awards.png"
import AwardsImg30 from "../images/awards/30-awards.png"
import Guidelines from "../images/awards/Guidelines.png"
import mademoiselleSecBG from "../images/awards/mademoiselle-bg.jpg"
import mademoiselleLogo from "../images/awards/mademoiselle.png"
import judgesTitle from "../images/awards/judges.png"


import GoldCOMMBIcon from "../images/awards/gold-anchor.svg"
import { Player, ControlBar } from 'video-react';
import AwardsModal from "../components/awards/modal.js"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Luis from "../images/awards/judges/luis.png"
import Nikki from "../images/awards/judges/nikki.png"
import David from "../images/awards/judges/david.png"
import Michele from "../images/awards/judges/michele.png"
import Kyla from "../images/awards/judges/kyla.png"
import Justin from "../images/awards/judges/justin.png"
import Christina from "../images/awards/judges/christina.png"
import Ajay from "../images/awards/judges/ajay.png"
import LiAnn from "../images/awards/judges/li-ann.png"
import Mark from "../images/awards/judges/mark.png"
import Andrea from "../images/awards/judges/andrea.png"

import awardsTitle from "../images/awards/awards-title.png"
import theBestTitle from "../images/awards/the-best-title.png"
import aboutBG from "../images/awards/aboutBG.jpg"

import topDesignLine from "../images/awards/top-new-awards-line.png"




const Awards = () => (
  <div className="eventsPage membersZone awards">
    <Helmet>
    </Helmet>
    <Layout>
      <Seo title=" Awards | COMMB" />
      <div className="awards contactSecWrapper">
        <Hero />
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/1JYAMJlFJJsm7gHxING7Ws/06662877c30cab81c0c7334a63fffb25/Line_5.png" className="topCOMMBLine" />
        <section className="mademoiselleSec">
          <h4 className="new-title">
            SAVE THE DATE
          </h4>
          <h5 className="new-sub-title">
            MAY 15TH
          </h5>
          <h4 className="new-title lg-font">
            ARCADIAN COURT
          </h4>
          <p className="address-font">
            401 Bay Street, Simpson, Tower 8th floor, Toronto, ON M5H 2Y4
          </p>
        </section>
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/1JYAMJlFJJsm7gHxING7Ws/06662877c30cab81c0c7334a63fffb25/Line_5.png" className="topCOMMBLine" />
        <section className="timelineInfo sec-1">
          <div className="container" style={{overflow: 'visible'}}>
            <h2 className="sponsor-title">
              2025 SPONSORS
            </h2>
          </div>
        </section>
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/1JYAMJlFJJsm7gHxING7Ws/06662877c30cab81c0c7334a63fffb25/Line_5.png" className="topCOMMBLine" />
        <section className="timelineInfo sec-2">
          <div className="container" style={{overflow: 'visible'}}>
            <h2 className="large-sponsor-title">
              Coming soon
            </h2>
          </div>
        </section>
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/1JYAMJlFJJsm7gHxING7Ws/06662877c30cab81c0c7334a63fffb25/Line_5.png" className="topCOMMBLine" />
        <section className="judges">
          <div className="container">
            <h2>
              2025
            </h2>
            <img src={judgesTitle} className="judgesImg" />
            <h2 className="large-sponsor-title">
              TO BE ANNOUNCED
            </h2>
          </div>
        </section>
        <img src="https://images.ctfassets.net/1l6si2vnlb2k/1JYAMJlFJJsm7gHxING7Ws/06662877c30cab81c0c7334a63fffb25/Line_5.png" className="topCOMMBLine" />
        <section className="aboutTheAwards">
          <div className="container">
            <h2>About The</h2>
            <img src={awardsTitle} className="awardsTitleImg" />
            <p>
              Launched in Spring 2023, the awards gala is an all-encompassing industry celebration recognizing  the creative, technical,
              and strategic achievements of agencies and  advertisers for their out-of-home campaigns.
            </p>
            <p>
              Winning categories are focused on innovation, data, use of programmatic/digital technology, audience targeting, 360 planning, and more.
              <br /><br />
            </p>
            <p>
              From outdoor to place-based media; from painted murals to 3D digital spectaculars and everything in between, The Canadian Out-of-Home Awards is celebrating success from every corner of our industry.
            </p>
            <p>
            The  out-of-home (OOH) industry in Canada is a rapidly growing channel in  the marketing and advertising sector.  OOH consists of media that  reaches people outside of their homes, such as billboards, posters,  digital signage, transit stop and vehicle ads, audio-visual displays,  experiential activations, and more.  A powerful way to reach large,  diverse audiences and create lasting impressions, OOH’s capabilities are  truly unmatched by any other medium.
            </p>
            <a href="https://www.youtube.com/@COMMB/videos" className="btnOutline">
              <span>Click Here for the Winners’ <br className="mobileOnly"/> Gallery of 2024 AWARDS</span>
            </a>
            <h2 className="lastTitle">For 2025 partnership inquiries, please contact <a href="mailto:awards@commb.ca">awards@commb.ca</a></h2>
          </div>
        </section>
        </div>
    </Layout>
  </div>
)

export default Awards