import React from "react"
import { Link } from "gatsby"

const Hero = () => {
    return (
        <div className="awards-Hero">
            <div className="container">
                <p className="mobileOnly">
                    The Canadian Out-of-Home Marketing & Measurement Bureau (COMMB) is pleased to present the annual Canadian Out-of-Home Awards in collaboration with their OOH operator members. 
                </p>
                <img src="https://images.ctfassets.net/1l6si2vnlb2k/2ZuJX5izpzDbM4ACXb170u/f42fdf33647c300a28f48064d705dd8c/Transparent_background_1.png" style={{ paddingTop: '16rem' }} className="heroAwardsLogo2024"/>
                <a href="https://canadianooh.awardsplatform.com/" className="btn" target="_blank">
                    SUBMIT YOUR CAMPAIGN NOW
                </a>
                <a href="https://downloads.ctfassets.net/1l6si2vnlb2k/4eoAw8SUvmvvS0aHK1RbMj/ac6564b3cc810b1082bd00c72e6f3d3a/2025_Canadian_OOH_Awards_-_Categories___Guidelines.pdf" className="btn btnOutline" target="_blank">
                VIEW CATEGORY GUIDELINES AND SUBMISSION INFO
                </a>
            </div>
        </div>
    )
}

export default Hero