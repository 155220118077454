import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"
import { Link } from "gatsby"

const ComingFall = ({ data }) => {
  const { heading, listHeading, listItems, listContent } =
    data.contentfulComingFall2022

  const [idx, setIdx] = useState(0)

  const [next, setNext] = useState(listItems[1]?.label)
  const [prev, setPrev] = useState("")

    const options = {
    renderNode: {
      [INLINES.HYPERLINK]: node => {
        if (node.data.uri.includes("youtube.com/embed")) {
          return (
            <iframe
              src={node.data.uri}
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
              style={{ width: "100%", height: "400px" }}
            ></iframe>
          )
        } else if (node.data.uri.includes("https://"))  {
          return (
            <a href={node.data.uri} target="_blank" rel="noreferrer">
              {node.content[0].value}
            </a>
          )
        } else  {
          return (
            <Link to={node.data.uri}>
              {node.content[0].value}
            </Link>
          )
        }
      },
    },
    }
  
  return (
    <div className="measurementPage meausrement2022">
      <Layout>
        <Seo title="Coming in 2025 - Measurement" />
        <div className="main_content_wrapper measurement_page">
          <div className="measurement_main_section">
            <div className="container">
              <div className="measurement_block" id="topSec">
                <div className="measurement_left_col">
                  <div className="measurement_sidebar_block" id="sidebar">
                    <div className="measurement_sidebar_content">
                      <div className="measurement_common_sidebar_div">
                        <div className="measurement_common_title_box active">
                          <h3>{listHeading}</h3>
                        </div>
                      </div>
                      <div className="measurement_common_sidebar_div">
                        <div className="measurement_common_list_ul">
                          <ul className="tabs">
                            {listItems.map((item, i) => {
                              return (
                                <li
                                  className={`tab-link ${
                                    idx === i && "active"
                                  }`}
                                  data-tab={`tab-${i}`}
                                  key={item.id}
                                  onClick={() => {
                                    setIdx(i)

                                    i < listItems.length - 1
                                      ? setNext(listItems.at(i + 1).label)
                                      : setNext("")

                                    i > 0
                                      ? setPrev(listItems.at(i - 1).label)
                                      : setPrev(-1)
                                  }}
                                >
                                  <a
                                    href="#0"
                                    className="common_tabs_title"
                                  >
                                    {item.label}
                                  </a>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="measurement_right_col resources_right_col cm_right_content">
                  <div className="planning_tools_right_content">
                    <div className="measurement_info_content_block">
                      <div className="measurement_info_title">
                        <h1>{heading}</h1>
                      </div>

                      <div className="tabs_data">
                        {listContent.map((content, i) => {
                          return (
                            i === idx && (
                              <div
                                className="tab-content active"
                                id={`tab-${i}`}
                                key={content.id}
                              >
                                <div className="commb_data_report">
                                  <p className="#0">{content.heading}</p>
                                </div>
                                <div className="measurement-richtext">
                                  {renderRichText(content.content, options)}
                                </div>
                              </div>
                            )
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="planning_navigator">
                    {/* <a className="navigator_text"></a> */}
                    {listItems.map(
                      (item, i) =>
                        item.label === prev &&
                        idx > 0 && (
                          <a
                            href="#topSec"
                            key={item.id}
                            onClick={() => {
                              setIdx(i)
                              setPrev(listItems.at(i - 1)?.label)
                              setNext(listItems.at(i + 1)?.label)
                            }}
                          >
                            <div className="planning_left_arrow">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="4.842"
                                height="8.071"
                                viewBox="0 0 4.842 8.071"
                              >
                                <g
                                  id="right-arrow"
                                  transform="translate(-1226.939 -692.432)"
                                >
                                  <path
                                    id="Icon_open-arrow-left"
                                    data-name="Icon open-arrow-left"
                                    d="M3.561,0,0,3,3.561,5.935V0Z"
                                    transform="translate(1231 699.427) rotate(180)"
                                    fill="#f8f8f8"
                                  ></path>
                                  <path
                                    id="Icon_open-arrow-left_-_Outline"
                                    data-name="Icon open-arrow-left - Outline"
                                    d="M4.061-1.076V6.995L-.781,3.008Zm-1,3.95v-1.8L.781,3l2.28,1.877v-2Z"
                                    transform="translate(1231 699.427) rotate(180)"
                                    fill="#f8f8f8"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            {prev}
                          </a>
                        )
                    )}
                    {listItems.map(
                      (item, i) =>
                        item.label === next &&
                        listItems.length - 1 !== idx && (
                          <a
                            href="#topSec"
                            key={item.id}
                            onClick={() => {
                              setIdx(i)

                              setNext(listItems.at(i + 1)?.label)
                              setPrev(listItems.at(i - 1)?.label)
                            }}
                            className="nextItem"
                          >
                            {next}
                            <div className="planning_right_arrow">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="4.842"
                                height="8.071"
                                viewBox="0 0 4.842 8.071"
                              >
                                <g
                                  id="right-arrow"
                                  transform="translate(-1226.939 -692.432)"
                                >
                                  <path
                                    id="Icon_open-arrow-left"
                                    data-name="Icon open-arrow-left"
                                    d="M3.561,0,0,3,3.561,5.935V0Z"
                                    transform="translate(1231 699.427) rotate(180)"
                                    fill="#f8f8f8"
                                  ></path>
                                  <path
                                    id="Icon_open-arrow-left_-_Outline"
                                    data-name="Icon open-arrow-left - Outline"
                                    d="M4.061-1.076V6.995L-.781,3.008Zm-1,3.95v-1.8L.781,3l2.28,1.877v-2Z"
                                    transform="translate(1231 699.427) rotate(180)"
                                    fill="#f8f8f8"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </a>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default ComingFall

export const query = graphql`
  query ComingFall($language: String) {
    contentfulComingFall2022(node_locale: { eq: $language }) {
      heading
      listHeading
      listItems {
        id
        label
      }
      listContent {
        id
        heading
        content {
          raw
        }
      }
    }
  }
`
